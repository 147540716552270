import React, { Suspense } from 'react';
import loadable from '@loadable/component';
import { ThemeProvider } from '@material-ui/core/styles';
import { Loading } from 'pages';
import { theme } from 'common/theme';
import 'common/styles/common.scss';

const PlatformRender = loadable(
    () => import(`platform/${process.env.REACT_APP_PLATFORM}`),
    {
        fallback: <Loading />
    }
);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<Loading />}>
                <PlatformRender />
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
