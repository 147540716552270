import { createMuiTheme } from '@material-ui/core/styles';
import { FONTSIZE, COLOR } from 'common/constants/appInfos';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: COLOR.GREEN,
            white: COLOR.WHITE,
            black: COLOR.BLACK
        },
        secondary: {
            main: COLOR.BLUE,
            azure: COLOR.AZURE,
            lightBlue: COLOR.LIGHTBLUE
        }
    },
    spacing: 2,
    typography: {
        fontFamily: ['LibreFranklin', 'NotoSansCJKsc'].join(',')
    },
    overrides: {
        MuiAppBar: {
            root: {
                zIndex: 1201
            }
        },
        MuiIconButton: {
            root: {
                color: COLOR.BLACK
            }
        },
        MuiAlert: {
            message: {
                fontSize: FONTSIZE.BODY
            }
        },
        MuiFormControlLabel: {
            labelPlacementStart: {
                marginRight: 0
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: FONTSIZE.BODY
            }
        },
        MuiFormLabel: {
            root: {
                color: COLOR.GREEN
            }
        }
    }
});
